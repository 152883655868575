export class Shop {
  constructor(element) {
    this.element = element;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const categoryMenu = this.element.querySelector(".category-filter");
    const footer = this.element.querySelector("footer");
    const main = document.querySelector("main");
    var category = urlParams.get('category');

    const categoriesSections = element.querySelectorAll(".category");

    const categoryFilters = element.querySelectorAll(".category-filter .filter");

    const activeCategory = element.querySelector(".filter[data-category='" + category + "']");

    if (activeCategory) {
      main.setAttribute("data-filter", category);
      categoryFilters.forEach((filter) => {
        filter.classList.remove('active');
      });

      activeCategory.classList.add('active');

    }


    categoriesSections.forEach((categorySection) => {

      const categoryId = categorySection.getAttribute("id");

      if (category === categoryId) {
        categorySection.classList.add('show');
      } else if (category === "all" || category == null) {
        categorySection.classList.add('show');
      }


    });

    categoryFilters.forEach((filter) => {
      filter.addEventListener("click", (e) => {
        e.preventDefault();
        footer.style.display = "none";
        category = filter.dataset.category;
        this.element.scrollTop = 0;
        if (category == "all"){
          categoriesSections.forEach((categorySection) => {
            categorySection.classList.remove('show');
            setTimeout(function (){
              categorySection.classList.add('show');
              footer.style.display = null;
              categoryMenu.style.display = null;
            }, 200);
          });
          categoryFilters.forEach((filter) => {
            filter.classList.remove("active");
          });
          filter.classList.add('active');


        }else{
          var ActiveCategorySection = document.getElementById(category);
          categoriesSections.forEach((categorySection) => {
            categorySection.classList.remove('show');
            setTimeout(function (){
              ActiveCategorySection.classList.add('show');
              footer.style.display = null;
              categoryMenu.style.display = null;
            }, 200);
          });
          categoryFilters.forEach((filter) => {
            filter.classList.remove("active");
          });
          filter.classList.add('active');
        }


      });
    })

    // HIDE FILTERS ON SCROLL

    var mainScrollDistance = this.scrollTop;
    var lastSectionScrollHeight = document.querySelector("section:last-of-type").offsetTop;

    document.querySelector("main").addEventListener("scroll", function () {
      mainScrollDistance = this.scrollTop + window.innerHeight;
      lastSectionScrollHeight = document.querySelector(".footer").offsetTop;
      if (mainScrollDistance > lastSectionScrollHeight) {
        categoryMenu.style.display = "none";
      } else {
        categoryMenu.style.display = null;
      }
    });
  }
}

document.querySelectorAll('main[data-id="shop"]').forEach((shopElement) => new Shop(shopElement));
