export const collapse = () => {

  var collapsibles = document.getElementsByClassName("collapsible");
  var i;

  Array.from(collapsibles).forEach((coll) => {

    // Open on load if active
    if (coll.classList.contains("active")) {
      var content = coll.nextElementSibling;
      content.style.maxHeight = content.scrollHeight + "px";
    }

    // Open on click
    coll.addEventListener("click", function (e) {

      if(coll.classList.contains("collapsible-slim") && window.innerWidth > 1024 || coll.classList.contains("collapsible-calendar")  && window.innerWidth > 1024){
        return false;
      }

      Array.from(collapsibles).forEach((coll) => {
        if (coll == this) {
          return false
        } else {
          var content = coll.nextElementSibling;
          content.style.maxHeight = null;
          content.classList.remove('collapsed');
          coll.classList.remove("active");
        }

      });

      this.classList.toggle("active");
      console.log(this);
      var content = this.nextElementSibling;
      if (content.style.maxHeight) {
        content.style.maxHeight = null;
        content.classList.remove('collapsed');
      } else {
        content.style.maxHeight = content.scrollHeight + "px";
        content.classList.add('collapsed');
      }
    });
  });


  const MoreInfoLinks = document.querySelectorAll('.day .more-info');

  MoreInfoLinks.forEach((link) =>{
    link.addEventListener("click", function (e){
      e.preventDefault();

      const eventDate = link.dataset.date;
      console.log(eventDate);

      Array.from(collapsibles).forEach((coll) => {
        coll.classList.remove('active');
        var content = coll.nextElementSibling;
        content.style.maxHeight = null;
      });


      const activeFilter = document.querySelector('.events-filter a[data-target="'+eventDate+'"]');
      const filter = document.querySelector('.events-filter a:not([data-target="'+eventDate+'"])');


         activeFilter.classList.add('active');
         filter.classList.remove('active');

         const events = document.querySelectorAll('.events-grid .block-type-event');
         events.forEach((event) =>{
           if(event.classList.contains(eventDate)){
             event.classList.add('show');
           }else{
             event.classList.remove('show');
           }

         });

      const target = link.getAttribute('href');
      const container = document.querySelector(".swiper-slide.no-flex .grid");
      const containerOffset = document.querySelector(".swiper-slide.no-flex .grid").offsetTop;

      const targetEl = document.querySelector(target);
      const collapsible = targetEl.querySelector(".collapsible");
      const content = collapsible.nextElementSibling;

      collapsible.classList.add('active');
      content.style.maxHeight = content.scrollHeight + "px";


      container.scroll({
        top: containerOffset,
        behavior: "smooth"
      });






    });
  });


  window.addEventListener('resize', () => {

    Array.from(collapsibles).forEach((coll) => {
      if (coll.classList.contains("active")) {
        var content = coll.nextElementSibling;
        coll.classList.remove('active');
        content.style.maxHeight = null;

        /*if (content.style.maxHeight) {
          content.style.maxHeight = null;
        } else {
          content.style.maxHeight = content.scrollHeight + "px";
        }*/
      }
    });

  });
}
