import Swiper, {
  Autoplay,
  EffectFade,
  FreeMode,
  HashNavigation,
  Lazy,
  Manipulation,
  Mousewheel,
  Navigation
} from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';

export const Carousel = () => {

  const slider = document.querySelector(".swiper-container");
  if (slider) {
    const swiperH = new Swiper('.swiper-container-menu', {
      modules: [Navigation, Lazy, Manipulation, EffectFade, Mousewheel, Autoplay],
      cssMode: false,
      direction: 'horizontal',
      followFinger: false,
      preloadImages: false,
      resizeObserver: false,
      allowTouchMove: false,
      loop: true,
      lazy: {
        checkInView: true,
      },
      autoplay: {
        delay: 2000,
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: false,
      },
      watchSlidesProgress: true,
      speed: 300,
      on: {
        init: function () {
          AutoPlaySwiper(this);
        },
        lazyImageReady: function () {
          console.log('load');
          if (this.el.classList.contains("hide")) {
            this.el.classList.remove("hide");
          }

          /*const total = (this.slides.length) * 2;
          countImages += 1;

          if(countImages === 2){
            setTimeout(function (){
              const total = swiperH.slides.length;
              for (var i= 0; i < total; i++){
                swiperH.lazy.loadInSlide(i);
              }
            }, 1000);
          }
          if(countImages === total){
          }*/
        },
      }
    });

    var countImages = 0;
    const swiperV = new Swiper('.swiper-container-v', {
      modules: [Lazy, Manipulation, EffectFade, Mousewheel, FreeMode, HashNavigation],
      cssMode: false,
      direction: 'vertical',
      hashNavigation: {
        replaceState: true,
      },
      mousewheel: {
        invert: false,
        sensitivity: 10,
        thresholdDelta: 10,
        releaseOnEdges: true,
        forceToAxis: true,
      },
      lazy: false,
      allowTouchMove: true,
      followFinger: false,
      preloadImages: false,
      resizeObserver: false,
      effect: 'slide',
      fadeEffect: {
        crossFade: true,
      },
      freeMode: {
        enabled: false,
        sticky: true,
      },
      slidesPerView: 1,
      speed: 200,
      on: {
        slideNextTransitionStart: function () {
          const header = document.querySelector('.header');
          header.classList.remove("light-theme");
          StopSwiper(swiperH);
        },
        slideNextTransitionEnd: function (){
          this.allowTouchMove = false;
          this.update();
        },
        slidePrevTransitionStart: function () {
          const header = document.querySelector('.header');
          const slideCalendar = document.querySelector(".swiper-slide.no-flex .grid");
          header.classList.add("light-theme");
          StartSwiper(swiperH);
          slideCalendar.scrollTop = 0;
        },
      },

    });



    /*AUTOPLAY ON MOBILE*/
    function AutoPlaySwiper(swiper) {
      if (window.innerWidth < 1024) {
        swiper.autoplay.start();
      } else {
        swiper.autoplay.stop();
      }
    }

    function StopSwiper(swiper) {
      if(swiper){
        swiper.autoplay.stop();
      }
    }

    function StartSwiper(swiper) {
      if (window.innerWidth < 1024) {
        swiper.autoplay.start();
      } else {
        swiper.autoplay.stop();
      }
    }

    window.addEventListener("resize", function () {
      AutoPlaySwiper(swiperH);
    });


    /*MENU HOVER SLIDE CHANGE*/

    var menuItems = document.querySelectorAll(".header-nav .nav-item a.swiper-trigger");
    var innerFooterTrigger = document?.querySelectorAll(".swiper-v-trigger");

    Array.from(menuItems).forEach(function (menuItem) {

      menuItem.addEventListener("mouseenter", () => {
        var swiper = menuItem.dataset.swiper;
        var slideTo = menuItem.dataset.slideto;
        console.log("slide to : " + slideTo);
        swiperH.slideTo(slideTo);
      });

    });


    if (innerFooterTrigger) {

      Array.from(innerFooterTrigger).forEach(function (trigger) {
        trigger.addEventListener('click', function () {
          var slideTo = trigger.dataset.slideto;
          swiperV.slideTo(slideTo);
        });
      });
    }


    // ENABLE SCROLLING IN SLIDES

    const ScrollContainer = document?.querySelector(".swiper-slide.no-flex .grid");


    if (ScrollContainer) {
      ScrollContainer.addEventListener("scroll", function () {

        console.log(ScrollContainer.scrollTop)
        if (ScrollContainer.scrollTop == 0) {
          swiperV.mousewheel.enable();
          swiperV.allowTouchMove = true;
        } else {
          swiperV.mousewheel.disable();
          swiperV.allowTouchMove = false;
          console.log("disabled");
        }
      });
    }

    const ephemerisMobile = document.querySelector(".swiper-slide-to");
    const menuInner = document.querySelector('.nav-inner');
    const header = document.querySelector("header");

    ephemerisMobile.addEventListener("click", function (){
      menuInner.classList.remove("is-active");
      header.classList.remove("has-menu-active");
      swiperV.slideTo(1, 0);
    });


  }

  // EPHEMERIS CLOSE MOBILE ON HOME PAGE




  /*CUSTOM SLIDES CHANGE ON HOVER*/

  /*var swiperBtn = document.querySelectorAll(".swiper-container-v .swiper-container");

  Array.from(swiperBtn).forEach(function (el) {
    el.addEventListener('mouseenter', function () {

      const dataTrigger = el.getAttribute("data-trigger");
      switch (dataTrigger) {
        case 'left':
          swiperLeft.slideNext();
          break;
        case 'right':
          swiperRight.slideNext();
          break;
      }


    });
  });



  /!*PROJECT LIST SCROLLING*!/

  var ScrollContainer = document.querySelector(".swiper-slide .projects-list");

  ScrollContainer.addEventListener("scroll", function () {

    console.log(ScrollContainer.scrollTop)
    if (ScrollContainer.scrollTop == 0) {
      swiperV.mousewheel.enable();
      swiperV.allowTouchMove = true;
    } else {
      swiperV.mousewheel.disable();
      swiperV.allowTouchMove = false;
    }
  });

  /!*MENU DISPLAY*!/

  var ProjectBtn = document.querySelector("#menu .btn[data-container='projects']");
  var AboutBtn = document.querySelector("#menu .btn[data-container='about']");
  var LogoBtn = document.querySelector("#menu .btn.logo");
  var MenuButtons = document.querySelectorAll("#menu .btn");
  var AboutContainer = document.querySelector(".menu-container");
  var ContentContainer = document.getElementById("loaded-content");

  ProjectBtn.addEventListener("click", function () {
    Array.from(MenuButtons).forEach(function (item) {
      item.classList.remove("active");
    });

    this.classList.add("active");

    if (!AboutContainer.classList.contains("hide") || !ContentContainer.classList.contains("hide")) {
      swiperV.slideTo(1, 0);
    } else {
      swiperV.slideTo(1);
    }

    AboutContainer.classList.add("hide");
    ContentContainer.classList.add("hide");
    history.pushState(null, null, "/");

  });

  LogoBtn.addEventListener("click", function () {

    Array.from(MenuButtons).forEach(function (item) {
      item.classList.remove("active");
    });

    if (!AboutContainer.classList.contains("hide") || !ContentContainer.classList.contains("hide")) {
      swiperV.slideTo(0, 0);
    } else {
      swiperV.slideTo(0);
    }

    AboutContainer.classList.add("hide");
    ContentContainer.classList.add("hide");
    history.pushState(null, null, "/");
  });

  AboutBtn.addEventListener("click", function () {

    Array.from(MenuButtons).forEach(function (item) {
      item.classList.remove("active");
    });

    AboutContainer.classList.remove("hide");

    this.classList.add("active");
    ContentContainer.classList.add("hide");
    history.pushState(null, null, "/");
  });*/


};

export const CarouselProduct = () => {

  const swiperProduct = new Swiper('.swiper-product', {
    modules: [Navigation, Lazy, Manipulation, EffectFade, Mousewheel, Autoplay, FreeMode],
    cssMode: false,
    direction: 'horizontal',
    followFinger: true,
    observer: true,
    preloadImages: false,
    resizeObserver: true,
    allowTouchMove: true,
    centeredSlides: true,
    freeMode: false,
    mousewheel: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
      enabled: false,
    },
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 3,
      loadOnTransitionStart: true,
      elementClass: "lazyload",
    },
    watchSlidesProgress: false,
    speed: 300,
    breakpoints: {
      // when window width is >= 320px
      1024: {
        direction: 'vertical',
        centeredSlides: true,
        navigation:{
          enabled: false,
        },

      },
    },
    on: {

      beforeInit: function () {
        if (swiperProduct) {
          swiperProduct.destroy();
        }
      },
      observerUpdate: function () {

      },
      lazyImageReady: function () {
        if (this.el.classList.contains("hide")) {
          this.el.classList.remove("hide");
          console.log('eeeee');
        }
      },
      resize: function () {
        this.navigation.update();
        this.update();
        if (window.innerWidth > 1024) {
          this.slideTo(0, 0);
          this.disable();
        } else {
          this.slideTo(0, 0);
          this.enable();
        }

      }
    }

  });

}


