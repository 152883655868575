const EventMenuFilters = document.querySelectorAll(".events-filter a");
const EventItems = document.querySelectorAll(".block-type-event");
EventMenuFilters.forEach((filter) =>{
  filter.addEventListener("click", (e)=>{
    e.preventDefault();
    const target = filter.dataset.target;

    EventMenuFilters.forEach((filter) =>{
      filter.classList.remove('active');
    });
    filter.classList.add('active');

    EventItems.forEach((event) =>{

      if(event.classList.contains(target)){
        event.classList.add("show");
      }else{
        event.classList.remove("show");
      }
    });
  })
});


