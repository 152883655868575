const menuToggle = document.querySelector('.menu-toggle');
const menuInner = document.querySelector('.nav-inner');
const header = document.querySelector("header");
const mobileCartToggler = document.getElementById("checkout")?.querySelector(".toggle-cart-xs");
const ephemerisHomePageToggler = document.querySelector(".nav-inner .swiper-v-trigger");
const ephemerisToggler = document.querySelectorAll(".go-to-page");

menuToggle.addEventListener("click", () =>{
  menuInner.classList.toggle("is-active");
  header.classList.toggle("has-menu-active");
});


window.addEventListener("resize", () => {
  menuInner.classList.remove("is-active");
  header.classList.remove("has-menu-active");
});


  mobileCartToggler?.addEventListener("click", () => {
    menuInner.classList.remove("is-active");
    header.classList.remove("has-menu-active");
  });


ephemerisHomePageToggler?.addEventListener("click", () => {
  setTimeout(function (){
    menuInner.classList.remove("is-active");
    header.classList.remove("has-menu-active");
  },200);
});

ephemerisToggler.forEach((toggler) => {

  toggler.addEventListener("click", function (e){
    e.preventDefault();
    const target = toggler.dataset.page;
    window.location.href = target;

  });

});
