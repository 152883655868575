// This script fills the `div.cart` element (e.g. /site/snippets/header.php).
// It also handles adding items, update items and deleting items from the cart.
// The instance of Cart is stored as a global variabel (`window.cart`).


import SlimSelect from "slim-select";

if(document.body.getAttribute('id') === "checkout"){
  window.isChecked = false;
}else{
  window.isChecked = undefined;
}

console.log(window.isChecked);

export class Cart {
  constructor(element) {
    this.lang = document.querySelector('html').lang;

    this.element = element;
    this.cartDetailsElement = document.querySelector('.details-cart');
    this.mobileCartToggler = document.querySelector('.nav-inner-container .toggle-cart-xs');
    this.countElement = document.querySelector('.cart-count');
    this.mobileCountElement = document.querySelector('.nav-inner-container .cart-count');

    // this object will be used to store the cart data we are loading from the api.
    this.data = {};

    // store language variables
    // this should be the same as in /site/languages/en.php
    this.i18n = {
      'cart.empty': 'Cart is empty.',
      'cart.hide': 'Hide Cart',
      'cart.item.remove': 'Remove',
      'cart.included-vat': 'VAT incl.',
      'cart.vat-included': 'VAT incl.',
      'cart.quantity': 'Quantity',
      'cart.items' : "Items",
      'cart.quantity-in-cart': 'in cart',
      'cart.change-quantity': 'Change quantity',
      'cart.price': 'Price',
      'cart.sum': 'Sum',
      'cart.shipping': 'Shipping',
      'cart.product': 'Product',
      'cart.free-shipping': 'free',
      'cart.calculated-at-checkout': 'Shipping calculated at check out',
      'cart.to-checkout': 'To Checkout',
      'cart.order.review': 'Order Review',
    };

    // overwrite default language variables
    if (this.lang === 'fr') {
      this.i18n = {
        'cart.empty': 'Le panier est vide',
        'cart.hide': 'Fermer le Panier',
        'cart.item.remove': 'Supprimer',
        'cart.included-vat': 'TVA incl.',
        'cart.vat-included': 'TVA incl.',
        'cart.quantity': 'Quantité',
        'cart.items' : "Articles",
        'cart.quantity-in-cart': 'Dans le panier',
        'cart.change-quantity': 'Modifier la quantité',
        'cart.price': 'Prix',
        'cart.sum': 'Total',
        'cart.shipping': 'Livraison',
        'cart.product': 'Produit',
        'cart.free-shipping': 'Gratuit',
        'cart.calculated-at-checkout': 'Livraison calculée au paiement',
        'cart.to-checkout': 'Commander',
        'cart.order.review': 'Commande',
      };
    }
    this.mobileCartToggler?.addEventListener('click', (event) => {
      event.stopPropagation();
      this.element.classList.add("open");
      this.cartDetailsElement.classList.add("color-red");
    });

    this.cartDetailsElement?.addEventListener('click', (event) => {
      event.stopPropagation();
      this.element.classList.add("open");
      this.cartDetailsElement.classList.add("color-red");
    });

    document.addEventListener('click', (e) => {
      this.element.classList.remove("open");
      this.cartDetailsElement.classList.remove("color-red");
    });

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.element.classList.remove("open");
        this.cartDetailsElement.classList.remove("color-red");
      }
    });

    // initially update & load cart data from api
    this.request("cart");
  }

  // helper method to handle different api request
  // the api endpoint is defined in /site/plugins/site/api.php
  request(endpoint, method = 'GET', data = null) {
    const {lang} = this;

    return fetch(`/api/shop/${endpoint}`, {
      method, headers: {
        'Content-Type': 'application/json', 'x-language': lang,
      }, body: method !== 'GET' ? JSON.stringify(data) : null,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === 'ok') {
          // store response data to data object.
          this.data = json.data;
          this.updateHTML();
          this.updateCount();
          console.log(this.data);
        } else {
          alert(json.message);
        }
        return json;
      });
  }

  updateCount() {
    const {data} = this;
    if (this.countElement) {
      if (data.quantity === 0) {
        this.countElement.innerText = '';
        this.mobileCountElement.innerText = '';
      } else {
        this.countElement.innerText = `[${data.quantity}]`;
        this.mobileCountElement.innerText = `[${data.quantity}]`;
      }
    }
  }

  updateHTML() {
    const {data, i18n} = this;

    function createQuantitySelect(item) {
      const ariaLabel = `${item.quantity} ${i18n['cart.quantity-in-cart']}. ${i18n['cart.change-quantity']}.`;
      const id = createValidHtmlId(item.title);
      console.log(id);
      let html = `<select  class="item-select" id="select-${id}" data-key="${item.key}" aria-label="${ariaLabel}">`;
      for (let i = 0; i <= item.maxAmount; i += 1) {
        html += `
          <option ${i === item.quantity ? 'selected' : ''}>
            ${i}
          </option>
        `;
      }
      html += '</select>';
      return html;
    }

    function createValidHtmlId(value) {
      const id = value ? value.replace(/\W/g, '_') : '_';
      return id.charAt(0).match(/[\d_]/g)?.length ? `id_${id}` : id;
    }

    function createCartItem(item) {
      return `
            <div class="t-item">
                <div class="i-name">
                    <a href="${item.url}">
                        <p class="txt-left text-xs">${item.categories}</p>
                        <p class="txt-left text-xs">${item.title}
                        ${item.variant ? `<span>${item.variant}</span></p>` : '</p>'}
                    </a>
                </div>
                <div class="i-info">
                    <div class="i-quantity t-row">
                        <h4>${i18n['cart.quantity']}</h4>
                        ${createQuantitySelect(item)}
                    </div>
                    <div class="i-price t-row">
                        <h4>${i18n['cart.price']}</h4>
                        ${item.price}
                    </div>
                </div>
         </div>
      `;
    }

    function createCartItems(items) {
      let html = '';
      items.forEach((item) => {
        html += createCartItem(item);
      });
      return html;
    }

    function createTaxRates(taxRates) {
      let html = '';
      taxRates.forEach((taxRate) => {
        html += `
                <div class="tax t-row">
                    <h4>${i18n['cart.included-vat']} (${taxRate.taxRate}%)</h4> ${taxRate.sum}
                </div>
        `;
      });
      return html;
    }

    if (data.quantity === 0) {
      this.element.innerHTML = `
        <div class="cart-header">
            <div class="cart-icon"></div>
            <div class="close-cart">
                <h4 class="txt-left">${i18n['cart.hide']}</h4>
            </div>
        </div>
        <div class="cart-info">
            <h4 class="txt-left">${i18n['cart.empty']}</h4>
        </div>
      `;
    } else {
      this.element.innerHTML = `
        ${(this.element.dataset.variant == 'checkout') ? `<p class="cart-title text-m">${i18n['cart.order.review']}</p>` : ''}
        <div class="cart-header">
        <div class="cart-icon">

        </div>
        <div class="close-cart">
            <h4 class="txt-left">${i18n['cart.hide']}</h4>
        </div>
        </div>
        <div class="table">
            <div class="t-header">
                <h4 class="txt-left">${i18n['cart.items']}</h4>
            </div>
            <div class="t-items">
                    ${createCartItems(data.items)}
            </div>
            <div class="t-foot">
                <div class="t-foot-header">
                    <div class="sum cart-shipping">
                        <h4 class="txt-left">${i18n['cart.calculated-at-checkout']}</h4>
                    </div>
                </div>
                  <div class="sum checkout-shipping t-row">
                    <h4>${i18n['cart.shipping']}</h4> ${data.shipping}
                  </div>
                  <div class="sum t-row">
                    <h4>${i18n['cart.sum']}</h4> ${data.sum}
                  </div>
                  ${createTaxRates(data.taxRates)}
            </div>
        </div>
        ${(this.element.dataset.variant !== 'checkout') ? `<a href="${data.checkoutUrl}" class="button txt-center">${i18n['cart.to-checkout']}</a>` : ''}
      `;

      // UPDATE QUANTITY
      this.element.querySelectorAll("select").forEach((selectElement, i) => {
        selectElement.addEventListener('change', (event,) => {
          const {key} = event.target.dataset;
          const quantity = event.target.value;
          var shippingCountry;
          if(window.isChecked === undefined){
            shippingCountry = null;
          }else if (window.isChecked === true){
            shippingCountry = document.querySelector('#shippingcountry + .country-select .placeholder').innerHTML;
          }else if (window.isChecked === false){
            shippingCountry = document.querySelector('#country + .country-select .placeholder').innerHTML;
          }
          this.update(key, quantity, shippingCountry);
        });
      });
    }


    // CLOSE CART BUTTON
    this.element.querySelector(".close-cart").addEventListener('click', (event) => {
      event.stopPropagation();
      this.element.classList.remove("open");
      this.cartDetailsElement.classList.remove("color-red");
    });

    this.element.querySelectorAll("select").forEach((selectElement, i) => {
      const id = '#'+selectElement.getAttribute('id');
      var Selecter = [];
      Selecter.push(new SlimSelect({
        select: id,
        allowDeselect: false,
        closeOnSelect: true,
        showSearch: false,
        onChange: (info) => {
        }
      }));
    });
  }

  add(id, quantity = 1) {
    this.element.classList.add('-loading');
    return this.request('cart', 'POST', {
      id, quantity,
    }).finally(() => {
      this.element.classList.add("open");
      this.element.classList.remove('-loading');
    });
  }

  update(id, quantity = 1, shippingCountry) {
    this.element.classList.add('-loading');
    return this.request('cart', 'PATCH', {
      id, quantity, shippingCountry,
    }).finally(() => {
      this.element.classList.remove('-loading');
    });
  }

  updateShippingCountry(shippingCountry) {
    this.element.classList.add('-loading');
    console.log('update');
    return this.request('cart', 'PATCH', {
      shippingCountry,
    }).finally(() => {
      this.element.classList.remove('-loading');
    });
  }
}

const cartElement = document.getElementById('cart');
if (cartElement) {
  // Store the instance of Cart as a global variable so other scripts can make use of Cart methods.
  window.cart = new Cart(cartElement);
}

